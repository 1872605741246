<!-- =========================================================================================
    File Name: ShiftsTable.vue
    Description: Таблица смен
========================================================================================== -->


<template>
    <div class="mt-4">
        <div class="flex flex-wrap items-stretch mb-3">
            <div class="w-full sm:w-1/2 h-full">
                <vs-input class="w-full" placeholder="Поиск по номеру смены"
                    id="number-search"
                    ref="numberSearch"
                    v-model="search" 
                    @input="debouncedGetData()" 
                    @keyup.esc="search = ''; debouncedGetData()" />
            </div>
            <div class="w-full sm:w-1/2 mb-3 pr-2 md:pl-2">
                <flat-pickr class="w-full" @input="debouncedGetData()"
                    v-model="dates_range" :config="configdateTimePicker" placeholder="Поиск по дате закрытия" />
            </div>
        </div>

        <vs-table 
            :data="data"
            :sst="true"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
            :max-items="filterForm.per_page"
            stripe>
            <template slot="thead">
                <vs-th>Смена</vs-th>
                <vs-th>Номер</vs-th>
                <vs-th>Сотрудник</vs-th>
                <vs-th>Время закрытия</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" ref="trs">
                    <vs-td :data="tr">{{tr.name}}</vs-td>
                    <vs-td :data="tr">{{tr.table_fields.shift_number}}</vs-td>
                    <vs-td :data="tr">{{tr.table_fields.shift_manager}}</vs-td>
                    <vs-td :data="tr">{{$helpers.formatDateTime(tr.table_fields.shift_end_date)}}</vs-td>
                    <template class="expand-shift" slot="expand">
                        <div class="con-expand-shifts w-full">
                            <div class="border border-dashed border-grey border-l-0  border-r-0 border-t-0 pl-8">
                                <vs-button type="border" color="success" class="mr-2" 
                                    @click="openIdealReport(tr.table_fields.shift_number)">
                                    Отчет
                                </vs-button>
                                <payments-table :shift_id="tr.id" />
                            </div>
                        </div>
                    </template>
                </vs-tr>
            </template>
        </vs-table>

        <vs-pagination v-if="getTotalPages > 1"
            class="my-4"
            :total="getTotalPages" v-model="p"
            :maxItems="1" :sizeArray="1"
            prev-icon="arrow_back" next-icon="arrow_forward"/>
    </div>
</template>


<script>
var qs = require('qs');
import BaseTable from './BaseTable';
import PaymentsTable from './PaymentsTable.vue';
import Inputmask from 'inputmask';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian as RussianLocale} from 'flatpickr/dist/l10n/ru.js';

export default {
    mixins: [BaseTable],
    name: 'shifts-table',
    components: {
        PaymentsTable,
        flatPickr
    },
    data() {
        return {
            dates_range: '',
            configdateTimePicker: {
                dateFormat: 'd/m/Y',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true,
                mode: 'range'
            }
        }
    },
    watch: {
        search() {
            for (let key of Object.keys(this.filterForm.fields_filter)){
                this.filterForm.fields_filter[key].equals = this.search;
            }
            this.filterForm.p = 0;
        },
        dates_range() {
            let dates_range = this.dates_range.split(' ');
            
            let from = dates_range[0];
            let to = dates_range[2];
            let date_from;
            let date_to;

            if (from) {
                from = from.split('/');
                date_from = new Date(from[2], from[1] - 1, from[0], 0, 0, 0);
                this.filterForm.date_from = Math.round(date_from.valueOf() / 1000);
            } else {
                this.filterForm.date_from = null;
            }

            if (to) {
                to = to.split('/');
                date_to = new Date(to[2], to[1] - 1, to[0], 23, 59, 59);
                this.filterForm.date_to = Math.round(date_to.valueOf() / 1000);
            } else {
                if (from) {
                    date_to = new Date(from[2], from[1] - 1, from[0], 23, 59, 59);
                    this.filterForm.date_to = Math.round(date_to.valueOf() / 1000);
                } else {
                    this.filterForm.date_to = null;
                }
            }
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.filterForm.per_page)
        }
    },
    methods: {
        downloadCSV() {
            this.$http({
                method: 'post',
                url: '/udata/emarket/export_shifts/',
                data: qs.stringify(this.filterForm),
                withCredentials: true,
                responseType: 'arraybuffer'
            }).then(response => {
                let filename = 'отчет_по_сменам__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv';
                this.$helpers.downloadCSV(response.data, filename);
            });
        },
        async getData() {
            await this.$http({
                method: 'post',
                url: '/udata/emarket/get_shifts/.json',
                data: qs.stringify(this.filterForm),
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    this.tableFields = response.data.table_fields;
                    this.data = Object.values(response.data.items);
                    this.total = response.data.total;
                } else {
                    this.$vs.notify({
                        title:'Ошибка',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        openIdealReport(shift_number) {
            this.$router.push({name: 'ideal-report', params: {shift_number: shift_number} });
        }
    },
    created() {
        this.getData()
        .then( () => {
            for (let key of Object.keys(this.tableFields)){
                this.filterForm.fields_filter[key] = { equals: null };
                this.filterForm.order_filter[key] = undefined;
            }        
        });
    },
    mounted() {
        var search_input= document.getElementById("number-search");
        Inputmask("9{*}").mask(search_input);
    }
}
</script>
