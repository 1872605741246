<!-- =========================================================================================
    File Name: Shifts.vue
    Description: Смены
========================================================================================== -->


<template>
    <vx-card :title="pageTitle">
        <vs-button class="mr-2" @click="downloadCSV()">СКАЧАТЬ</vs-button>
        <vs-button class="mr-2" @click="closeShiftPrompt();">ЗАКРЫТЬ СМЕНУ</vs-button>

      <vs-popup title="Закрытие смены" :active.sync="closeShiftPromptActive">
        <div>
            Вы уверены, что хотите закрыть смену #{{currentShiftNumber}}?
            <vs-list>
                <vs-list-header title="Суммы оплат по кассам" color="danger"></vs-list-header>
                <vs-list-item v-for="(item, key, index) in currentShiftTotals" :key="index"
                    :title="key" :subtitle="item.toString() + ' руб.'" />
            </vs-list>
        </div>
        <vs-divider />
        <div class="flex flex-wrap">
            <api-button class="mb-2 mx-2 flex-auto" :click="closeShift">ЗАКРЫТЬ СМЕНУ</api-button>
            <vs-button class="mb-2 mx-2 flex-auto" @click="closeShiftPromptActive = false">ОТМЕНА</vs-button>
        </div>
      </vs-popup>

        <shifts-table v-if="isAdmin()" ref="table" />
        <payments-table v-if="isCashier()" ref="table" :currentShiftNumber="currentShiftNumber"/>
    </vx-card>
</template>


<script>
var qs = require('qs');

import ShiftsTable from '../components/ShiftsTable.vue'
import PaymentsTable from '../components/PaymentsTable.vue'

export default {
    components: {
        ShiftsTable, 
        PaymentsTable
    },
    data() {
        return {
            closeShiftPromptActive: false,
            currentShiftTotals: {},
            currentShiftNumber: ''
        }
    },
    computed: {
        pageTitle() {
            return this.isAdmin() ? 'Смены' : 'Текущая смена';
        }
    },
    methods: {
        downloadCSV() {
            this.$refs.table.downloadCSV();
        },
        closeShiftPrompt() {
            this.closeShiftPromptActive = true;
            this.getCurrentShiftInfo();
        },
        async closeShift() {
            let payload = {
                manager_id: await this.$store.state.auth.managerId(),
            };
            this.$http({
                method: 'post',
                url: '/udata/emarket/close_shift/.json',
                data: qs.stringify(payload),
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    this.$vs.notify({
                        title: 'Смена удачно закрыта',
                        color:'success',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    });
                    this.closeShiftPromptActive = false;
                    this.$refs.table.getData();

                    let filename = 'оплаты_по_смене_' + this.currentShiftNumber + '__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv';
                    this.$helpers.downloadCSV(response.data, filename);
                } else {
                    this.$vs.notify({
                        title:'Ошибка',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        getCurrentShiftInfo() {
            this.$http({
                method: 'get',
                url: '/udata/emarket/get_payments/.json',
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    this.currentShiftTotals = response.data.payments_totals;
                    this.currentShiftNumber = response.data.shift_number;
                } else {
                    this.$vs.notify({
                        title:'Ошибка',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        isAdmin() {
            return this.$acl.check('admin');
        },
        isCashier() {
            return this.$acl.check('cashier') && !this.$acl.check('admin');
        }
    },
    mounted() {
        this.getCurrentShiftInfo();
    }
}
</script>
