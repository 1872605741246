<!-- =========================================================================================
    File Name: PaymentsTable.vue
    Description: Таблица оплат по сменам
========================================================================================== -->


<template>
    <div class="mt-4">
        <vs-table 
            :data="data"
            :sst="true"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
            :max-items="filterForm.per_page"
            stripe hoverFlat>

            <template slot="thead">
                <vs-th>Оплата</vs-th>
                <vs-th>Касса</vs-th>
                <vs-th>Сумма</vs-th>
                <vs-th>Сотрудник</vs-th>
                <vs-th>Время</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" ref="trs">
                    <vs-td :data="tr">{{tr.name}}</vs-td>
                    <vs-td :data="tr">{{tr.payment_type_name}}</vs-td>
                    <vs-td :data="tr">{{tr.payment_amount}} руб.</vs-td>
                    <vs-td :data="tr">{{tr.manager}}</vs-td>
                    <vs-td :data="tr">{{$helpers.formatDateTime(tr.payment_time)}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-pagination v-if="getTotalPages > 1"
            class="my-4"
            :total="getTotalPages" v-model="p"
            :maxItems="1" :sizeArray="1"
            prev-icon="arrow_back" next-icon="arrow_forward"/>
    </div>
</template>


<script>
var qs = require('qs');
import BaseTable from './BaseTable';

export default {
    mixins: [BaseTable],
    name: 'payments-table',
    props: {
        shift_id: {
            default: ''
        },
        currentShiftNumber: [String, Number]
    },
    methods: {
        downloadCSV() {
            this.$http({
                method: 'post',
                url: '/udata/emarket/export_shifts/last',
                responseType: 'arraybuffer',
                withCredentials: true
            }).then(response => {
                let filename = 'отчет_по_смене_' + this.currentShiftNumber + '__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv';
                this.$helpers.downloadCSV(response.data, filename);
            });
        },
        async getData() {
            await this.$http({
                method: 'post',
                url: '/udata/emarket/get_payments/' + this.shift_id + '/.json',
                data: qs.stringify(this.filterForm),
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    this.tableFields = response.data.table_fields;
                    this.data = response.data.items ? Object.values(response.data.items) : [];
                    this.total = response.data.total;
                } else {
                    this.$vs.notify({
                        title:'Ошибка',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        }
    },
    created() {
        this.getData()
        .then( () => {
            for (let key of Object.keys(this.tableFields)){
                this.filterForm.fields_filter[key] = { equals: null };
                this.filterForm.order_filter[key] = undefined;
            }        
        });
    }
}
</script>
